import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { authorization } from "module/auth";
import { useHistory } from "react-router";
import { Box, CircularProgress } from "@material-ui/core";
import backIcon from "static/media/back.svg";
import { DialogModalContext } from "components/dialog-modal/DialogModal";
import CONFIG from "config/config";
import { FeatureFlagContext } from "context/featureFlagContext";

import { Header } from "./components/Header";
import { PageTitle } from "./components/PageTitle/PageTitle";
import { useCampaignData } from "./context/CampaignDataContext";
import { ADM_NAVIGATION } from "./constants";
import { validateUserAccess } from "./utils";

import { Segments } from "./Segments/Segments";
import { Design } from "./Design/Design";

import "./Builder.css";

const renderActiveTab = (activeTabIndex: number) => {
  switch (activeTabIndex) {
    case 0:
      return <Segments />;
    case 1:
      return <Design />;
    default:
      return <div>{ADM_NAVIGATION[activeTabIndex].pageTitle}</div>;
  }
};
export const Builder = () => {
  const { campaignId, clientId } = useParams();
  const { setCampaignId, setClientId, campaignData, isLoading, activeTabIndex, setActiveTabIndex } = useCampaignData();
  const user = authorization.getUser();
  const history = useHistory();
  const runDialogModal = useContext(DialogModalContext);
  const isClient = authorization.isClient();
  const homeUrl = useMemo(() => `${CONFIG.PLATFORM_BASE_URL}${isClient ? `/dashboard/home` : ""}`, [isClient]);
  const { featureFlags } = useContext(FeatureFlagContext);
  const isAdmEnabled = featureFlags.isAdmEnabled;

  const handleHomeClick = (e?: MouseEvent) => {
    const isCtrlClick = e?.ctrlKey || e?.metaKey;
    if (isCtrlClick) {
      return;
    } else if (!isCtrlClick && !campaignData?.isSubmitted) {
      runDialogModal({
        title: "Exit campaign creation?",
        contentText: (
          <Box style={{ padding: "10px 40px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div />
            <div style={{ textAlign: "center" }}>
              Are you sure you want to exit the campaign creation? Any unsaved progress will be lost.
            </div>
          </Box>
        ),
        ctaLabel: "Exit",
        handleCTAClick: () => {
          window.location.href = homeUrl;
        },
        secondaryButtonLabel: "Cancel",
        hasOptions: true,
      });
    }
  };

  const renderBackButton = () => {
    switch (activeTabIndex) {
      case 0:
        return (
          <Box className="backButton" onClick={() => handleHomeClick()}>
            <img src={backIcon} className="backButtonIcon" />
            Back to home
          </Box>
        );

      default:
        return (
          <Box className="backButton" onClick={() => setActiveTabIndex(activeTabIndex - 1)}>
            <img src={backIcon} className="backButtonIcon" />
            Back to {ADM_NAVIGATION[activeTabIndex - 1].title}
          </Box>
        );
    }
  };
  useEffect(() => {
    validateUserAccess(history, user, clientId);
    setCampaignId(campaignId);
    setClientId(clientId);
  }, [clientId]);

  if (isLoading || !campaignData) {
    return (
      <div className="loadingContainer">
        <CircularProgress size={20} color="secondary" style={{ marginRight: 10 }} />
      </div>
    );
  }

  if (!isAdmEnabled) {
    return null;
  }

  return (
    <>
      <Header
        activeTabIndex={activeTabIndex}
        campaignData={campaignData}
        handleHomeClick={handleHomeClick}
        setActiveTabIndex={setActiveTabIndex}
        tabs={ADM_NAVIGATION}
        homeUrl={homeUrl}
      />
      <div className="mainContainer">
        <div className="contentContainer">
          <div className="titleSection">
            {renderBackButton()}
            <PageTitle>{ADM_NAVIGATION[activeTabIndex].pageTitle}</PageTitle>
          </div>
          {renderActiveTab(activeTabIndex)}
        </div>
      </div>
    </>
  );
};
