import { SelectOption } from "components/form/SelectField";
import { RadioCardOption } from "components/form/RadioCardField";

import { NavigationItem } from "./components/Header/types";

import postcardImage from "../../../static/media/format-postcard.svg";
import letterImage from "../../../static/media/format-letter.svg";
import wideLetterImage from "../../../static/media/format-wide-letter.svg";
import flatImage from "../../../static/media/format-flat.svg";

const HEADER_TITLE = {
  DESIGN: "Design",
  SEGMENTS: "Segments",
  SUBMIT: "Submit",
};

const PAGE_TITLE = {
  DESIGN: "Design",
  SEGMENTS: "Define the audience for your campaign",
  SUBMIT: "Submit",
};

export enum ADM_STEP {
  SEGMENTS = "segments",
  DESIGN = "design",
  SUBMIT = "submit",
}

export const ADM_NAVIGATION: NavigationItem[] = [
  {
    title: HEADER_TITLE.SEGMENTS,
    pageTitle: PAGE_TITLE.SEGMENTS,
    step: ADM_STEP.SEGMENTS,
    index: 1,
  },
  {
    title: HEADER_TITLE.DESIGN,
    pageTitle: PAGE_TITLE.DESIGN,
    step: ADM_STEP.DESIGN,
    index: 2,
  },
  {
    title: HEADER_TITLE.SUBMIT,
    pageTitle: PAGE_TITLE.SUBMIT,
    step: ADM_STEP.SUBMIT,
    index: 3,
  },
];

export const RECIPIENT_MOVED_OPTIONS: SelectOption[] = [
  {
    value: "yes",
    label: "Send to new address",
    description: "Send the campaign to the new address provided by USPS",
  },
  {
    value: "no",
    label: "Don't send",
    description: "Remove the addressee from the campaign",
  },
];

export enum ADM_FORMAT {
  POSTCARD = "POSTCARD",
  LETTER = "LETTER",
  WIDE_LETTER = "WIDE_LETTER",
  FLAT = "FLAT",
}

export const FORMAT_OPTIONS: RadioCardOption[] = [
  {
    value: ADM_FORMAT.POSTCARD,
    label: '6" x 4"',
    topText: "Postcard",
    bottomText: "$",
    image: postcardImage,
  },
  {
    value: ADM_FORMAT.LETTER,
    label: '9" x 6"',
    topText: "Postcard",
    bottomText: "$$",
    image: letterImage,
  },
  {
    value: ADM_FORMAT.WIDE_LETTER,
    label: '11" x 6"',
    topText: "Postcard",
    bottomText: "$$$",
    image: wideLetterImage,
  },
  {
    value: ADM_FORMAT.FLAT,
    label: '15" x 12"',
    topText: "Postcard",
    bottomText: "$$$$",
    image: flatImage,
  },
];

export const MINIMUM_BUSINESS_DAYS = 5;
export const MAXIMUM_CAMPAIGN_DAYS = 360;
