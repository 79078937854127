import { Box, CircularProgress } from "@material-ui/core";

import { CampaignInfoFormData } from "../../schemas/campaignInformation";
import { CampaignDetailsSection } from "./components/CampaignDetailsSection";
import { TargetingSection } from "./components/TargetingSection";

import "./CampaignSummaryCard.css";
import { TotalSection } from "./components/TotalSection";
import { PrimaryButton } from "./components/PrimaryButton";
import { Audience } from "../../types";

export type CampaignSummaryCardProps = {
  buttonText: string;
  campaign: CampaignInfoFormData;
  flyerInfo?: {
    flyersAmount: number;
    totalCost: number;
  };
  isNextEnabled: boolean;
  onClickNext: () => void;
  targeting?: Audience[];
  isLoading?: boolean;
};

export const CampaignSummaryCard = ({
  campaign,
  flyerInfo,
  buttonText,
  targeting,
  onClickNext,
  isNextEnabled,
  isLoading,
}: CampaignSummaryCardProps) => {
  return (
    <Box className="summaryCardContainer">
      <Box className="campaignSummaryContainer">
        {isLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(255, 255, 255, 0.7)"
            zIndex={1}
          >
            <CircularProgress size={40} color="secondary" />
          </Box>
        )}
        <CampaignDetailsSection campaign={campaign} />
        {targeting && <TargetingSection audiences={targeting} />}
        {flyerInfo && <TotalSection total={flyerInfo.totalCost} units={flyerInfo.flyersAmount} />}
      </Box>
      <div className="primaryButtonContainer">
        <PrimaryButton disabled={!isNextEnabled || isLoading} onClick={onClickNext}>
          {buttonText}
        </PrimaryButton>
      </div>
    </Box>
  );
};
