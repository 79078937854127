import { Box, Grid } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import cn from "classnames";
import { UseFormReturn } from "react-hook-form";
import { addDays } from "date-fns";

import { InputField } from "components/form/InputField";
import { DateField } from "components/form/DateField";
import { SelectField } from "components/form/SelectField";
import { RadioCardField } from "components/form/RadioCardField";

import "./CampaignInfoCard.css";
import { ToggleText } from "../ToggleText";
import { TitleText } from "../TitleText";
import { FORMAT_OPTIONS, MINIMUM_BUSINESS_DAYS, MAXIMUM_CAMPAIGN_DAYS, RECIPIENT_MOVED_OPTIONS } from "../../constants";
import { CampaignInfoFormData } from "../../schemas/campaignInformation";
import checkCircleIcon from "../../../../../static/media/check-circle.svg";
import { getNextDayByBusinessDays } from "../../utils";
interface CampaignInfoCardProps {
  form: UseFormReturn<CampaignInfoFormData>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  triggerAnalyticsEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
  checkingCampaignName: boolean;
}

export const CampaignInfoCard = ({
  form,
  isOpen,
  setIsOpen,
  triggerAnalyticsEvent,
  checkingCampaignName,
}: CampaignInfoCardProps) => {
  const {
    register,
    formState: { errors, isValid },
    setValue,
    watch,
    trigger,
  } = form;

  return (
    <Grid className={`infoCardContainer ${!isOpen ? "closed" : ""}`} xs={12}>
      <Box className="infoCardHeaderContainer">
        <div className="titleContainer">
          <TitleText>Campaign Info</TitleText>
          {isValid && <img src={checkCircleIcon} alt="" className="checkIconInfoCard" />}
        </div>

        <div className="seeMoreToggle" onClick={() => setIsOpen(!isOpen)}>
          <ToggleText>See {isOpen ? "less" : "more"}</ToggleText>
          <KeyboardArrowDownIcon fontSize="small" className={cn("toggleIcon", isOpen && "toggleIconOpen")} />
        </div>
      </Box>
      <form>
        <InputField
          label="Campaign name"
          placeholder="Campaign name"
          error={errors.campaignName?.message}
          {...register("campaignName")}
          value={watch("campaignName")}
          onChange={(e) => {
            setValue("campaignName", e.target.value);
            trigger("campaignName");
          }}
          onBlur={() => {
            trigger("campaignName");
          }}
          required
          loading={checkingCampaignName}
        />
        <DateField
          label="Launch date"
          description="The date your campaign will be sent via USPS to begin delivery"
          name="campaignStartDate"
          error={errors.campaignStartDate?.message}
          onChange={(date) => {
            date && setValue("campaignStartDate", date);
            trigger("campaignStartDate");
          }}
          value={watch("campaignStartDate")}
          minDate={getNextDayByBusinessDays(new Date(), MINIMUM_BUSINESS_DAYS)}
          maxDate={addDays(new Date(), MAXIMUM_CAMPAIGN_DAYS)}
          required
          isUS
        />
        <SelectField
          label="When recipient moved"
          description="Select the action to take when the recipient moves"
          name="updateAddressOnMove"
          error={errors.updateAddressOnMove?.message}
          value={watch("updateAddressOnMove")}
          onChange={(value) => {
            setValue("updateAddressOnMove", value as string);
            trigger("updateAddressOnMove");
            triggerAnalyticsEvent("ADM NCOA address choice", {
              ncoaChoice: value === "yes" ? "Send to new address" : "Don't send to new address",
            });
          }}
          options={RECIPIENT_MOVED_OPTIONS}
          required
        />
        <RadioCardField
          label="Format"
          description="It influences your campaign total cost"
          name="format"
          error={errors.format?.message}
          value={watch("format")}
          onChange={(value) => {
            setValue("format", value);
            trigger("format");
          }}
          options={FORMAT_OPTIONS}
          required
        />
      </form>
    </Grid>
  );
};
