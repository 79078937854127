import { Audience } from "pages/adm/builder/types";
import { useState } from "react";
import cn from "classnames";
import { CircularProgress, withStyles, Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import checkCircleIcon from "static/media/check-circle.svg";
import { useCampaignData } from "pages/adm/builder/context/CampaignDataContext";
import { apiClient } from "module/api";
import "./AudienceCard.css";

interface AudienceCardProps {
  audience: Audience;
  key: string;
  selected: boolean;
  handleOpenAudienceMenu: (e: React.MouseEvent<HTMLButtonElement>, audience: Audience) => void;
  isDeleting: boolean;
  triggerAnalyticsEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
}

export const AudienceCard = ({
  audience,
  key,
  selected,
  handleOpenAudienceMenu,
  isDeleting,
  triggerAnalyticsEvent,
}: AudienceCardProps) => {
  const { campaignId, refetchCampaignAudiences, refetchAudiences } = useCampaignData();
  const [loading, setLoading] = useState(false);

  const handleSelectUnselectAudience = async () => {
    if (loading) return;
    setLoading(true);
    if (selected) {
      await apiClient.unlinkAudienceWithCampaign(campaignId, audience.id);
    } else {
      await apiClient.linkAudienceWithCampaign(campaignId, audience.id);
      triggerAnalyticsEvent("Selected ADM audience for campaign", {
        amountOfAudiences: audience.metadata.rowsAmount,
      });
    }
    await refetchAudiences();
    await refetchCampaignAudiences();
    setLoading(false);
  };

  return (
    <div
      className={cn("audienceCard", { ["selectedAudienceCard"]: selected && !loading })}
      key={key}
      onClick={handleSelectUnselectAudience}
    >
      {selected && !loading && <img src={checkCircleIcon} alt="" className="checkIcon" />}
      {loading || isDeleting ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        <>
          <div className="audienceNameAndAmount">
            <span className="audienceCardTitle">{audience.name}</span>
            <span>{audience.metadata.rowsAmount} units</span>
          </div>

          <div className="audienceCardCostAndMore">
            <span className="audienceCardCost">$1540</span>
            <button className="moreButton" onClick={(e) => handleOpenAudienceMenu(e, audience)}>
              <MoreHorizIcon />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
