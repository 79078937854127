import { Grid } from "@material-ui/core";
import { AudienceCreationModal } from "../components/AudienceCreationModal";
import { CampaignInfoCard } from "../components/CampaignInfoCard";
import { CampaignSummaryCard } from "../components/CampaignSummaryCard";
import { CampaignTargetingCard } from "../components/CampaignTargetingCard";
import useSegments from "./hooks/useSegments";

export enum SEGMENT_STEPS {
  CAMPAIGN_INFO = "CAMPAIGN_INFO",
  TARGETING = "TARGETING",
}

const BUTTON_TITLE_MAP = {
  [SEGMENT_STEPS.CAMPAIGN_INFO]: "Continue to Targeting",
  [SEGMENT_STEPS.TARGETING]: "Continue to Design",
};

export const Segments = () => {
  const {
    isCreateAudienceModalOpen,
    setIsCreateAudienceModalOpen,
    clickedAudience,
    checkingCampaignName,
    triggerAnalyticsEvent,
    methods,
    isCampaignInfoOpen,
    setIsCampaignInfoOpen,
    isTargetingCardOpen,
    setIsTargetingCardOpen,
    setClickedAudience,
    currentValues,
    activeAudienceDetails,
    currentStep,
    isNextEnabled,
    isLoading,
    onSubmit,
  } = useSegments();

  return (
    <Grid container className="segmentsContainer" spacing={3}>
      {isCreateAudienceModalOpen && (
        <AudienceCreationModal
          isOpen={isCreateAudienceModalOpen}
          setIsOpen={setIsCreateAudienceModalOpen}
          audience={clickedAudience}
          triggerAnalyticsEvent={triggerAnalyticsEvent}
        />
      )}
      <Grid item xs={12} md={8}>
        <CampaignInfoCard
          form={methods}
          isOpen={isCampaignInfoOpen}
          setIsOpen={setIsCampaignInfoOpen}
          checkingCampaignName={checkingCampaignName}
          triggerAnalyticsEvent={triggerAnalyticsEvent}
        />
        <CampaignTargetingCard
          isOpen={isTargetingCardOpen}
          setIsOpen={setIsTargetingCardOpen}
          setIsCreateAudienceModalOpen={setIsCreateAudienceModalOpen}
          clickedAudience={clickedAudience}
          setClickedAudience={setClickedAudience}
          triggerAnalyticsEvent={triggerAnalyticsEvent}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CampaignSummaryCard
          campaign={currentValues}
          targeting={activeAudienceDetails}
          flyerInfo={{ flyersAmount: 10, totalCost: 100 }}
          onClickNext={() => methods.handleSubmit(onSubmit)()}
          buttonText={BUTTON_TITLE_MAP[currentStep]}
          isNextEnabled={isNextEnabled}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};
