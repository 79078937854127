import { useState } from "react";
import cn from "classnames";
import { CustomTextInput } from "components/form/InputField/components/CustomTextInput";
import { Typography, CircularProgress } from "@material-ui/core";
import { SelectField } from "components/form/SelectField";
import { FileStick } from "static/media/FileStick";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useParams } from "react-router-dom";
import { downloadStringAsFile } from "utils/files";
import CloseIcon from "@material-ui/icons/Close";
import { DeleteIcon } from "static/media/delete-icon";
import { DragAndDropArea } from "pages/flyer/flyerTypePage/components/DragAndDropArea";
import { Download } from "static/media/download";
import { apiClient } from "module/api";
import { Audience } from "../../types";
import { useADMDragAndDrop } from "./useADMDragAndDrop";
import { audienceHeadersData, templateDefaultRecipients } from "./templateData";
import { useCampaignData } from "../../context/CampaignDataContext";
import "./AudienceCreationModal.css";

const requiredFields = ["first_name", "last_name", "address_1", "city", "state", "zip_code"];
const headersMapInitialState = {
  first_name: "",
  last_name: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zip_code: "",
};

interface SegmentsCreationModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  audience: Audience | null;
  triggerAnalyticsEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
}

const getNewAudienceName = (audiences: Audience[]) => {
  if (!audiences?.length) return "New Audience";
  const audiencesWithDefaultName = audiences.filter((audience) => audience.name.includes("New Audience"));
  if (!audiencesWithDefaultName.length) return "New Audience";
  const maxAudienceNumber = audiencesWithDefaultName.reduce((acc: number, curr: Audience) => {
    const currentNumber = curr.name.split("New Audience ")[1];
    if (currentNumber && Number(currentNumber) > acc) {
      acc = Number(currentNumber);
      return acc;
    } else {
      return acc;
    }
  }, 0);
  if (audiencesWithDefaultName) {
    return `New Audience ${maxAudienceNumber + 1}`;
  } else {
    return "New Audience";
  }
};

export const AudienceCreationModal = ({
  isOpen,
  setIsOpen,
  audience,
  triggerAnalyticsEvent,
}: SegmentsCreationModalProps) => {
  const { refetchAudiences, refetchCampaignAudiences, audiences } = useCampaignData();
  const { clientId, campaignId } = useParams();
  const [files, setFiles] = useState<Array<any>>([]);
  const [audienceNameError, setAudienceNameError] = useState<string>("");
  const [audienceName, setAudienceName] = useState<string>(audience?.name || getNewAudienceName(audiences));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [headersMap, setHeadersMap] = useState<{ [key: string]: string }>(
    audience?.metadata?.headersMap || headersMapInitialState
  );
  const [interactedWithMapper, setInteractedWithMapper] = useState<boolean>(false);

  const { isUploading, fileError, setFileMetadata, onDrop, onDropRejected, fileMetadata } = useADMDragAndDrop({
    audience,
    setFiles,
    maxFileSize: 175,
    clientId,
    triggerAnalyticsEvent,
  });

  const handleTemplateDownload = () => {
    const headers = audienceHeadersData.map((headerData) => headerData.value);
    const printingCostsTemplate =
      headers.join(",") + "\n" + templateDefaultRecipients.map((recipient) => recipient.join(",") + "\n").join("");
    downloadStringAsFile(printingCostsTemplate, "ADMAudienceTemplate.csv", "text/csv");
    triggerAnalyticsEvent("Downloaded ADM audience template");
  };

  const handleDeleteFile = () => {
    // TODO DELETE FILE FROM S3
    setFiles([]);
    setFileMetadata(null);
  };

  const isValidFileMetadata = () => {
    if (
      !fileMetadata ||
      !fileMetadata.fileUrl ||
      !fileMetadata.headers ||
      !fileMetadata.rowsAmount ||
      !fileMetadata.firstRow
    )
      return false;
    return true;
  };

  const disabledSubmit = () => {
    const requiredFieldsMissing = requiredFields.filter((field) => !headersMap[field]);
    return (
      !!fileError ||
      !files?.length ||
      !!requiredFieldsMissing.length ||
      !fileMetadata ||
      !isValidFileMetadata() ||
      !!audienceNameError
    );
  };

  const handleHeaderMapChange = (headerName: string, value: string) => {
    setHeadersMap((prev) => ({ ...prev, [headerName]: value }));
    if (!interactedWithMapper) {
      triggerAnalyticsEvent("Mapped ADM audience CSV columns");
      setInteractedWithMapper(true);
    }
  };

  const handleAudienceSubmit = async () => {
    if (disabledSubmit()) return;
    try {
      setIsSubmitting(true);
      triggerAnalyticsEvent("Saved uploaded ADM audience without filters");
      if (audience) {
        const updatedAudience = await apiClient.updateAudience({
          audienceId: audience.id,
          body: {
            name: audienceName,
            csv_url: fileMetadata?.fileUrl,
            metadata: { ...fileMetadata, headersMap },
            client_id: String(clientId),
          },
        });
        if (updatedAudience.status !== 200) {
          throw new Error("Failed to edit the audience");
        }
      } else {
        const createAudienceResponse = await apiClient.createAudience({
          name: audienceName,
          csv_url: fileMetadata?.fileUrl,
          metadata: { ...fileMetadata, headersMap },
          client_id: clientId,
        });
        if (createAudienceResponse.status !== 201) {
          throw new Error("Failed to create the audience");
        }
        const createdAudience = await createAudienceResponse.json();
        const createCampaignAudienceResponse = await apiClient.createCampaignAudience({
          audience_id: createdAudience.id,
          campaign_id: campaignId,
        });
        if (createCampaignAudienceResponse.status !== 201) {
          throw new Error("Failed to create the CampaignAudience");
        }
      }
      setIsSubmitting(false);
      refetchAudiences();
      refetchCampaignAudiences();
      handleClose();
    } catch (e) {
      setIsSubmitting(false);
      console.error("error while saving the audience: ", e);
    }
  };

  const getColumnSample = (column: string) => {
    if (!column || !fileMetadata?.headers) return "-";
    const currentIndex = fileMetadata?.headers.findIndex((header) => header === column);
    if (currentIndex === -1 || currentIndex === undefined) return "-";

    return fileMetadata?.firstRow[currentIndex];
  };

  const handleClose = () => {
    setFiles([]);
    setAudienceName("New Audience");
    setFileMetadata(null);
    setHeadersMap(headersMapInitialState);
    setIsOpen(false);
  };

  const handleAudienceNameChange = (newAudienceName: string) => {
    const isAnotherAudienceWithSameName = audiences.find((audience) => audience.name.trim() === newAudienceName.trim());
    if (isAnotherAudienceWithSameName && newAudienceName !== audience?.name) {
      setAudienceNameError("Another audience with this name already exists");
    } else {
      setAudienceNameError("");
    }
    setAudienceName(newAudienceName);
  };

  if (!isOpen) return null;
  return (
    <div className="modalBackground">
      <div className="modalContent">
        <div className="modalHeader">
          <button className="closeButton" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="modalTitleContainer">
          <span className="modalTitle">Create an audience</span>
        </div>
        <div className="audienceNameWrapper">
          <label>
            Audience name <span className="required">*</span>
          </label>
          <CustomTextInput
            disabled={false}
            value={audienceName}
            onChange={(e) => handleAudienceNameChange(e.target.value)}
          />
          <span className="audienceNameError">{audienceNameError}</span>
        </div>
        <div className="audienceDescriptionWrapper">
          <Typography>Follow the audience template, which should include at least:</Typography>
          <Typography>
            <div className="customListBullet" /> 2 columns for addressee identification: First Name, and Last Name.
          </Typography>
          <Typography>
            <div className="customListBullet" /> 4 columns for the complete address: Address, City, State, and Zip Code.
          </Typography>
        </div>
        <div className="downloadTemplateButtonWrapper">
          <button className="downloadTemplateButton" onClick={handleTemplateDownload}>
            <Download /> Download audience template
          </button>
        </div>
        {isUploading ? (
          <CircularProgress size={20} color="secondary" style={{ marginTop: 10, alignSelf: "flex-start" }} />
        ) : files?.length ? (
          <div className="uploadedCSVFile">
            <FileStick className="fileStick" />
            <div className="fileData">
              <span className="fileName">{files[0].name} </span>
              <span className="rowsAmount">{fileMetadata?.rowsAmount} rows</span>
            </div>
            <button className="deleteIcon" onClick={handleDeleteFile}>
              <DeleteIcon />
            </button>
          </div>
        ) : (
          <div className="dragAndDropWrapper">
            <DragAndDropArea
              files={[]}
              newVersion
              onDrop={onDrop}
              onDropRejected={onDropRejected}
              fileType="text/csv"
              instructionsText="Click to select or drag and drop your CSV"
              maxFileSize={175}
              alwaysDesktop
            />
          </div>
        )}
        {fileError && (
          <div className="fileError">
            <ErrorOutlineIcon style={{ marginRight: "5px", fontSize: "1.3em" }} />
            {fileError}
          </div>
        )}

        {!fileError && !isUploading && !!files?.length && (
          <div className="audienceHeadersMapperWrapper">
            <span className="setCsvColumnsTitle">Set the CSV columns up</span>
            <div className="audienceHeadersMapperRow">
              <span>Field</span>
              <span>CSV Column</span>
              <span>Column sample</span>
            </div>
            {audienceHeadersData.map((audienceHeaderData) => {
              const isRequired = !!requiredFields.find((field) => field === audienceHeaderData.value);
              return (
                <div key={audienceHeaderData.value} className="audienceHeadersMapperRow">
                  <span>
                    {audienceHeaderData.label} {isRequired && <span className="required">*</span>}
                  </span>
                  <SelectField
                    label=""
                    value={headersMap[audienceHeaderData.value]}
                    onChange={(value) => handleHeaderMapChange(audienceHeaderData.value, (value as string) || "")}
                    name={audienceHeaderData.value}
                    options={fileMetadata?.headers.map((header) => ({ value: header, label: header })) || []}
                    placeholder="Select the corresponding column"
                  />
                  <span>{getColumnSample(headersMap[audienceHeaderData.value])}</span>
                </div>
              );
            })}
          </div>
        )}
        <div className="footer">
          <button
            className={cn("createAudienceButtonSubmit", { ["cursorDefault"]: disabledSubmit() })}
            disabled={disabledSubmit()}
            onClick={handleAudienceSubmit}
          >
            {`${audience ? "Save" : "Create"} audience`}
            {isSubmitting && (
              <CircularProgress size={20} color="secondary" style={{ position: "absolute", right: "10px" }} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
