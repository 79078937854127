import papaparse from "papaparse";
import { useState, useEffect, useCallback } from "react";
import { apiClient } from "module/api";
import { Audience } from "../../types";

interface UseDragAndDropProps {
  audience: Audience | null;
  setFiles: (files: File[]) => void;
  maxFileSize?: number; // in MB
  clientId: string;
  triggerAnalyticsEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
}

interface FileRejection {
  errors: { code: string }[];
}

export const useADMDragAndDrop = ({
  audience,
  setFiles,
  maxFileSize = 25,
  clientId,
  triggerAnalyticsEvent,
}: UseDragAndDropProps) => {
  const [tempFiles, setTempFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);
  const [shouldFireUploadHandler, setShouldFireUploadHandler] = useState(false);
  const [fileMetadata, setFileMetadata] = useState<{
    headers: string[];
    firstRow: string[];
    rowsAmount: number;
    fileUrl?: string;
  } | null>(audience?.metadata || null);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.some((f: File) => f.size > maxFileSize * 1048576)) {
        setTempFiles([]);
        setFileMetadata(null);
        setFileError(`Error: The file exceeded the ${maxFileSize}mb limit`);
      } else if (acceptedFiles.length) {
        setFileError(null);
        papaparse.parse(acceptedFiles[0], {
          complete: (result: any) => {
            const { data } = result;
            let parseError;

            if (data.length < 1002) {
              parseError = "CSV must have at least 1001 rows (excluding headers).";
              setFileError(parseError);
              return;
            }

            if (data[0].length < 6) {
              parseError = "CSV must have at least 6 columns.";
              setFileError(parseError);
              return;
            }

            if (!parseError) {
              setTempFiles(acceptedFiles);
              setFileMetadata({ headers: data[0], firstRow: data[1], rowsAmount: data.length - 1 });
              setShouldFireUploadHandler(true);
              triggerAnalyticsEvent("Uploaded an ADM audience CSV");
            }

            if (parseError) {
              setFileError(parseError);
              triggerAnalyticsEvent("Saw error on ADM audience CSV upload", {
                csvError: parseError,
              });
            }
          },
        });
      }
    },
    [setTempFiles, setFileError]
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          const fileError = "Error: You can upload only CSV file";
          setFileError(fileError);
          triggerAnalyticsEvent("Saw error on ADM audience CSV upload", {
            csvError: fileError,
          });
        }
      });
    });
  };

  const uploadHandler = async () => {
    if (!tempFiles?.length) return;
    const file = tempFiles[0];
    setFileError(null);
    setIsUploading(true);
    setFileUploadProgress(0);

    try {
      const presignedUrlResponse = await apiClient.getPresignedUrl({ fileName: file.name, clientId });
      if (presignedUrlResponse.status !== 200) {
        throw new Error("Failed to get the presigened url");
      }

      const presignedUrlData = await presignedUrlResponse.json();

      if (!presignedUrlData?.url) {
        throw new Error("Failed to get the presigened url");
      }

      const response = await fetch(presignedUrlData.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      setFileMetadata({ ...fileMetadata!, fileUrl: presignedUrlData.dbURL });
      setFiles([tempFiles[0]]);
      setTempFiles([]);
      setFileUploadProgress(100);
    } catch (error) {
      setFileUploadProgress(0);
      console.error({ error }, "uploadFile error");
      setFileError("There was an error while uploading the file");
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (shouldFireUploadHandler && tempFiles.length > 0 && !fileError && fileMetadata) {
      setShouldFireUploadHandler(false);
      uploadHandler();
    }
  }, [shouldFireUploadHandler]);

  useEffect(() => {
    if (audience?.csv_url) {
      const fileName = audience?.csv_url.split("/").pop();
      setFiles([{ name: fileName } as File]);
    }
  }, [audience]);

  return {
    isUploading,
    setIsUploading,
    fileUploadProgress,
    setFileUploadProgress,
    tempFiles,
    setTempFiles,
    fileError,
    onDrop,
    onDropRejected,
    fileMetadata,
    setFileMetadata,
  };
};
