import { useState, useContext } from "react";
import { Box, Grid, Typography, CircularProgress, withStyles, Menu, MenuItem } from "@material-ui/core";
import { Edit } from "static/media/edit";
import { DeleteIcon } from "static/media/delete-icon";
import { SnackBarContext } from "components/snack-bar";
import { apiClient } from "module/api";
import AddIcon from "@material-ui/icons/Add";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { EmptyTargetingImage } from "static/media/emptyTargetingImage";
import cn from "classnames";
import { Audience } from "../../types";
import { useCampaignData } from "../../context/CampaignDataContext";
import "./CampaignTargetingCard.css";
import { ToggleText } from "../ToggleText";
import { TitleText } from "../TitleText";
import { AudienceCard } from "./components/AudienceCard";
import checkCircleIcon from "../../../../../static/media/check-circle.svg";

interface CampaignTargetingCardProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsCreateAudienceModalOpen: (isOpen: boolean) => void;
  clickedAudience: Audience | null;
  setClickedAudience: (audience: Audience | null) => void;
  triggerAnalyticsEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
}

export const CampaignTargetingCard = ({
  isOpen,
  setIsOpen,
  setIsCreateAudienceModalOpen,
  clickedAudience,
  setClickedAudience,
  triggerAnalyticsEvent,
}: CampaignTargetingCardProps) => {
  const runSnackBar = useContext(SnackBarContext);
  const { audiences, audiencesLoading, campaignAudiences, refetchCampaignAudiences, campaignId, refetchAudiences } =
    useCampaignData();
  const [isDeleting, setIsDeleting] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props: any) => {
    const { anchorEl, ...menuProps } = props;
    return (
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        {...menuProps}
      />
    );
  });

  const handleOpenAudienceMenu = (e: React.MouseEvent<HTMLButtonElement>, audience: Audience) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setClickedAudience(audience);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setClickedAudience(null);
  };

  const handleDeleteAudience = async () => {
    if (!clickedAudience) return;
    setIsDeleting(true);
    if (campaignAudiences.find((ca) => ca.audience_id === clickedAudience.id)) {
      await apiClient.unlinkAudienceWithCampaign(campaignId, clickedAudience.id);
    }
    const deleteAudienceEndpointResponse = await apiClient.deleteAudience(clickedAudience.id);
    if (deleteAudienceEndpointResponse.status !== 204) {
      const deleteAudienceResponse = await deleteAudienceEndpointResponse.json();
      if (deleteAudienceResponse?.error?.name === "SequelizeForeignKeyConstraintError") {
        runSnackBar({
          type: "error",
          vertical: "bottom",
          horizontal: "right",
          msg: "This audience is in use by another campaign and can't be deleted",
        });
      }
    } else {
      runSnackBar({
        type: "success",
        vertical: "bottom",
        horizontal: "right",
        msg: "The audience was deleted.",
      });
    }

    handleCloseMenu();
    await refetchAudiences();
    await refetchCampaignAudiences();
    setIsDeleting(false);
  };

  const handleEditAudience = () => {
    setAnchorEl(null);
    setIsCreateAudienceModalOpen(true);
  };

  const handleCreateAudience = () => {
    setClickedAudience(null);
    setIsCreateAudienceModalOpen(true);
    triggerAnalyticsEvent("Clicked to create an ADM audience");
  };

  const renderTargetintCardContent = () => {
    if (audiencesLoading) return <CircularProgress size={20} color="secondary" />;
    return audiences?.length ? (
      <>
        <div className="audiencesHeader">
          <span>Choose or create an audience </span>
          <button className="createAudienceButton" onClick={handleCreateAudience}>
            Create audience <AddIcon fontSize="small" />
          </button>
        </div>
        <div className="audiencesCardsContainer">
          {audiences.map((audience) => {
            return (
              <AudienceCard
                key={audience.name}
                audience={audience}
                selected={!!campaignAudiences.find((ca) => ca.audience_id === audience.id)}
                handleOpenAudienceMenu={handleOpenAudienceMenu}
                isDeleting={isDeleting && audience.id === clickedAudience?.id}
                triggerAnalyticsEvent={triggerAnalyticsEvent}
              />
            );
          })}
        </div>
      </>
    ) : (
      <>
        <EmptyTargetingImage />
        <Typography variant="body2">
          Upload a list of targets with addresses and additional details to create a audience.
        </Typography>
        <button className="createAudienceButton" onClick={handleCreateAudience}>
          Create audience <AddIcon fontSize="small" />
        </button>
      </>
    );
  };

  return (
    <Grid className={`targetingCardContainer ${!isOpen ? "closed" : ""}`} xs={12}>
      <Box className="targetingCardHeaderContainer">
        <div className="titleContainer">
          <TitleText>Targeting</TitleText>
          <img src={checkCircleIcon} alt="" className="checkIcon" />
        </div>

        <div className="seeMoreToggle" onClick={() => setIsOpen(!isOpen)}>
          <ToggleText>See {isOpen ? "less" : "more"}</ToggleText>
          <KeyboardArrowDownIcon fontSize="small" className={cn("toggleIcon", isOpen && "toggleIconOpen")} />
        </div>
      </Box>
      <Box className={cn("targetingContent", { ["targetingContentWithAudiences"]: audiences?.length })}>
        {renderTargetintCardContent()}
        {!isDeleting && (
          <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            <MenuItem>
              <div className="audienceMenuItem" onClick={handleEditAudience}>
                <span>Edit audience</span> <Edit width="19" height="19" />
              </div>
            </MenuItem>
            <MenuItem>
              <div className="audienceMenuItem" onClick={handleDeleteAudience}>
                <span>Delete</span> <DeleteIcon width="19" height="19" />
              </div>
            </MenuItem>
          </StyledMenu>
        )}
      </Box>
    </Grid>
  );
};
