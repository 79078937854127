import { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { useQuery } from "react-query";
import { apiClient } from "../../../../module/api";
import { ADMCampaignData, Audience, CampaignAudience } from "../types";
import { getIndexOfCurrentStep } from "../utils";
import { CAMPAIGN_INTERNAL_STATUSES } from "../../../constants";
import { getCampaign } from "../../../../graphQL";

interface CampaignDataContextProps {
  activeTabIndex: number;
  campaignData: ADMCampaignData | null;
  error: Error | null;
  isLoading: boolean;
  refetchCampaign: () => void;
  setActiveTabIndex: (index: number) => void;
  setCampaignId: (campaignId: string) => void;
  setClientId: (clientId: string) => void;
  campaignId: string | null;
  clientId: string | null;
  audiences: Audience[];
  audiencesError: Error | null;
  audiencesLoading: boolean;
  refetchAudiences: () => void;
  campaignAudiences: CampaignAudience[];
  campaignAudiencesError: Error | null;
  campaignAudiencesLoading: boolean;
  refetchCampaignAudiences: () => void;
}

const CampaignDataContext = createContext<CampaignDataContextProps | undefined>(undefined);

export const CampaignDataProvider = ({ children }: { children: ReactNode }) => {
  const [clientId, setClientId] = useState<string | null>(null);
  const [campaignId, setCampaignId] = useState<string | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const {
    data: campaignData = null,
    error,
    isLoading,
    refetch,
  } = useQuery<ADMCampaignData | null>({
    queryKey: ["campaign", clientId, campaignId],
    queryFn: async () => {
      if (!campaignId || !clientId) {
        return null;
      }
      const campaign: ADMCampaignData = await getCampaign(campaignId, clientId);
      campaign.isSubmitted =
        !!campaign.internalStatus &&
        campaign.internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT &&
        campaign.internalStatus !== CAMPAIGN_INTERNAL_STATUSES.REJECTED;
      return campaign;
    },
  });

  const {
    data: audiences = [],
    error: audiencesError,
    isLoading: audiencesLoading,
    refetch: refetchAudiences,
  } = useQuery<Audience[]>({
    queryKey: ["audiences", clientId],
    queryFn: async () => {
      if (!clientId) {
        return [];
      }
      const clientAudiencesResponse = await apiClient.getClientAudiences(clientId);
      if (clientAudiencesResponse.status !== 200) {
        return [];
      }
      const fetchedClientAudiences = await clientAudiencesResponse.json();
      return fetchedClientAudiences.audiences;
    },
  });

  const {
    data: campaignAudiences = [],
    error: campaignAudiencesError,
    isLoading: campaignAudiencesLoading,
    refetch: refetchCampaignAudiences,
  } = useQuery<CampaignAudience[]>({
    queryKey: ["campaignAudiences", campaignId],
    queryFn: async () => {
      if (!campaignId) {
        return [];
      }
      const campaignAudiencesResponse = await apiClient.getCampaignAudiences(campaignId);
      if (campaignAudiencesResponse.status !== 200) {
        return [];
      }
      const fetchedCampaignAudiences = await campaignAudiencesResponse.json();
      return fetchedCampaignAudiences;
    },
  });

  useEffect(() => {
    if (campaignData && !activeTabIndex) {
      setActiveTabIndex(getIndexOfCurrentStep(campaignData.lastActiveStep));
    }
  }, [campaignData?.lastActiveStep]);

  return (
    <CampaignDataContext.Provider
      value={{
        activeTabIndex,
        campaignData,
        error: error as Error | null,
        isLoading,
        refetchCampaign: refetch,
        setActiveTabIndex,
        setCampaignId,
        setClientId,
        campaignId,
        clientId,
        refetchAudiences,
        audiencesLoading,
        audiences,
        audiencesError: audiencesError as Error | null,
        campaignAudiences,
        campaignAudiencesError: campaignAudiencesError as Error | null,
        campaignAudiencesLoading,
        refetchCampaignAudiences,
      }}
    >
      {children}
    </CampaignDataContext.Provider>
  );
};

export const useCampaignData = () => {
  const context = useContext(CampaignDataContext);
  if (context === undefined) {
    throw new Error("useCampaignData must be used within a CampaignDataProvider");
  }
  return context;
};
