import cn from "classnames";
import { useState, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { DistributionLocation, SelectedRoute, SuggestedRoute } from "module/eddm/dto";
import { LocationLatLngType } from "components/place-autocomplete/type";
import Draggable from "react-draggable";
import { GoogleMap } from "@react-google-maps/api";
import {
  handleHoverSelectedMissionUpdate,
  handleHoverMultipleSelectedRoutes,
  getSelectedTargetsAsString,
} from "components/audience-map/utils";
import { VisiblePostCodeType } from "store/types";
import { useStore } from "store";
import { makeStyles, Box } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { stylesV2 } from "./stylesV2";
import { EmptyAddressesState } from "./components";
import { AddressCardV2 } from "./components/AddressCard/AddressCardV2";
import { AudienceAttributes } from "./components/AudienceAttributes/AudienceAttributes";
import { SuggestedRoutesBlock } from "./components/AddressCard/SuggestedRoutesBlock/SuggestedRoutesBlock";

const useStyles = makeStyles(() => stylesV2);

interface AddressBlockProps {
  addresses: DistributionLocation[];
  onAddressCardClick: (address: DistributionLocation) => void;
  handleDeleteLocation: (deletedLocation: DistributionLocation) => void;
  handleAutocompleteChanged: (autocompleteResult: LocationLatLngType) => void;
  getFilteredPostCodes?: (key: string) => VisiblePostCodeType[];
  selectedRoutes: SelectedRoute[];
  setSelectedRoutes: (selectedRoutes: SelectedRoute[] | ((selectedRoutes: SelectedRoute[]) => SelectedRoute[])) => void;
  googleMap: GoogleMap | null;
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  setIsUndoOrRedo: (isUndoOrRedo: boolean) => void;
  setIsSingleRouteSelection: (isSingleSelection: boolean) => void;
  setShouldHideStatistics: (shouldHideStatistics: boolean) => void;
  suggestedRoutes: SuggestedRoute[];
  setDrawingMode: (drawingMode: null | string) => void;
}

export const AddressBlockV3 = ({
  addresses,
  onAddressCardClick,
  handleDeleteLocation,
  handleAutocompleteChanged,
  getFilteredPostCodes,
  selectedRoutes,
  setSelectedRoutes,
  googleMap,
  setShouldAutoSave,
  setIsUndoOrRedo,
  setIsSingleRouteSelection,
  setShouldHideStatistics,
  suggestedRoutes,
  setDrawingMode,
}: AddressBlockProps) => {
  const posthog = usePostHog();
  const postHogTracker = new EmitAnalyticsEvent(posthog);
  const {
    user,
    country,
    client: { name: clientName },
    map: { loading },
    campaign: { isSubmitted, id: campaignId },
    distributionLocations,
    selectedTargets,
    suggestionsEnabled,
    updateSelectedTargets,
    setSuggestionsEnabled,
  } = useStore();
  const noSuggestedRoutesSelectedBlockPosition = window.innerHeight / 3.5;
  const suggestedRoutesEnabledSelectedBlockInitialPosition = window.innerHeight * 0.73;
  const suggestedRoutesEnabledSelectedBlockTopBound = window.innerHeight / 2.5;
  const suggestedRoutesEnabledSelectedBlockBottomBound = window.innerHeight * 0.8;

  const [bounds, setBounds] = useState({
    top: suggestedRoutesEnabledSelectedBlockTopBound,
    bottom: suggestedRoutesEnabledSelectedBlockBottomBound,
    right: 0,
    left: 0,
  });

  const [controlledPosition, setControlledPosition] = useState({
    x: 0,
    y: suggestedRoutesEnabledSelectedBlockInitialPosition,
  });

  useEffect(() => {
    const newBounds = suggestionsEnabled
      ? {
          top: suggestedRoutesEnabledSelectedBlockTopBound,
          bottom: suggestedRoutesEnabledSelectedBlockBottomBound,
          right: 0,
          left: 0,
        }
      : {
          top: noSuggestedRoutesSelectedBlockPosition,
          bottom: noSuggestedRoutesSelectedBlockPosition,
          right: 0,
          left: 0,
        };
    setBounds(newBounds);
    if (!suggestionsEnabled) {
      setControlledPosition({ x: 0, y: noSuggestedRoutesSelectedBlockPosition });
    } else {
      setControlledPosition({ x: 0, y: suggestedRoutesEnabledSelectedBlockInitialPosition });
    }
  }, [suggestionsEnabled]);

  const classes = useStyles();

  if (!addresses || addresses.length === 0) return null;

  const handleSuggestRoutes = () => {
    setDrawingMode(null);
    postHogTracker.run({
      eventName: "Clicked to get more route suggestions",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: {
        campaignId,
        audienceAttributes: getSelectedTargetsAsString(selectedTargets),
      },
    });
    setSuggestionsEnabled(true);
    setShouldAutoSave(true);
  };

  const onControlledDrag = (e: any, position: { x: number; y: number }) => {
    const { y } = position;
    setControlledPosition((prev) => ({ ...prev, y }));
  };

  return (
    <div
      className={cn(classes.addressesContainer, "mob-hidden")}
      onMouseEnter={() => setShouldHideStatistics(true)}
      onMouseLeave={() => setShouldHideStatistics(false)}
    >
      <div className={classes.addressBlockHeader}>
        <div className={classes.title}>Add more locations</div>
        <Box className={cn(classes.searchBarWrapper)}>
          <PlaceAutocomplete
            countryCode={country?.code}
            disabled={isSubmitted || loading}
            placeholder={"Enter a city, neighborhood, or address"}
            postCodeSearch={true}
            onPlaceChanged={handleAutocompleteChanged}
            getFilteredPostCodes={getFilteredPostCodes}
          />
        </Box>
      </div>
      <AudienceAttributes
        suggestionsEnabled={suggestionsEnabled}
        selectedTargets={selectedTargets}
        updateSelectedTargets={updateSelectedTargets}
        onSubmit={handleSuggestRoutes}
        setShouldAutoSave={setShouldAutoSave}
        suggestedRoutes={suggestedRoutes}
      />

      {suggestionsEnabled && !!suggestedRoutes?.length && (
        <div>
          <SuggestedRoutesBlock
            suggestedRoutes={suggestedRoutes}
            googleMap={googleMap}
            setSelectedRoutes={setSelectedRoutes}
            setShouldAutoSave={setShouldAutoSave}
            setIsSingleRouteSelection={setIsSingleRouteSelection}
            setIsUndoOrRedo={setIsUndoOrRedo}
          />
        </div>
      )}
      <Draggable bounds={bounds} position={controlledPosition} onDrag={onControlledDrag}>
        <div
          className={cn(classes.selectedLocationsBlock, {
            [classes.selectedLocationsBlockNoSuggestions]: !suggestionsEnabled,
          })}
        >
          <div className={classes.expandLocationsBlockButton} />
          <div
            className={cn(classes.locationsCardsContainer, {
              [classes.hideScroll]: addresses.length === 1 && !selectedRoutes?.length,
            })}
          >
            <span
              className={classes.selectedRoutes}
              onMouseOver={() => {
                handleHoverMultipleSelectedRoutes({ mapInit: googleMap, selectedRoutes });
              }}
              onMouseLeave={() => handleHoverSelectedMissionUpdate({ mapInit: googleMap, hoveredMission: null })}
            >
              <CheckIcon fontSize="small" className={classes.selectedRoutesCheck} /> <span>Selected routes</span>
            </span>
            {addresses.map((address) => {
              return (
                <AddressCardV2
                  address={address}
                  key={address.name}
                  onClick={() => onAddressCardClick(address)}
                  handleDeleteLocation={handleDeleteLocation}
                  setSelectedRoutes={setSelectedRoutes}
                  googleMap={googleMap}
                  setShouldAutoSave={setShouldAutoSave}
                  setIsUndoOrRedo={setIsUndoOrRedo}
                  setIsSingleRouteSelection={setIsSingleRouteSelection}
                />
              );
            })}
          </div>
          {!selectedRoutes.length && distributionLocations?.length === 1 && (
            <div className={classes.emptyStateContainer}>
              <EmptyAddressesState />
              <span className={classes.emptyStateText}>Click on the map areas to choose your delivery routes.</span>
            </div>
          )}
        </div>
      </Draggable>
    </div>
  );
};
